//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-8:_2984,_4711,_8284,_872,_4208,_7368,_1268,_8864;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-8')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-8', "_2984,_4711,_8284,_872,_4208,_7368,_1268,_8864");
        }
      }catch (ex) {
        console.error(ex);
      }